import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { UPDATE_ONE_EXERCISE } from "../../../apollo/mutations";
import { GET_WORKOUT_FORM_DATA } from "../../../apollo/queries";
import { ReactComponent as Ball } from "../../../images/ball.svg";
import { ReactComponent as Dumbell } from "../../../images/dumbell.svg";
import { ReactComponent as Kettlebells } from "../../../images/kettlebells.svg";
import { ReactComponent as Roller } from "../../../images/roller.svg";
import { ReactComponent as Rope } from "../../../images/rope.svg";
import { theme } from "../../../utils/theme";
import { ImageUpload } from "../../Segments/ImageUpload";
import { VideoUpload } from "../../Segments/VideoUpload";
import Feed from "feed-media-audio-player";

import {
  BACK_BUTTON,
  Err,
  Loading,
  SAVE_BUTTON,
  Switch,
  TEXTAREA,
  TEXT_INPUT,
} from "../../UI";
import { Selector } from "../../UI/Selector";
import { Exercises } from "../Exercises/WorkoutExercises";
import { DeleteWorkout } from "./DeleteWorkout";

const workoutSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  isActive: Yup.boolean(),
  imageUrl: Yup.string().required("Please upload image"),
  skillLevel: Yup.string().required("Difficulty field is required"),
  duration: Yup.string().required("Duration field is required"),
  categoryId: Yup.string().required("Category Field is required"),
  description: Yup.string().required("Description field is required"),
  videoUrl: Yup.string().required("Please upload workout video"),
  pro: Yup.string().required("Pro field is required"),
  exerciseCheck: Yup.boolean().oneOf(
    [true],
    "All Exercises must be checked after changes"
  ),
});

export const WorkoutForm = (props) => {
  let history = useHistory();

  const categoryId = props.cateogryId;
  const workoutId = props.workoutId;
  const { workout, exerciseCheck } = props;

  // formik

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: workoutSchema,
    initialValues: {
      title: workout ? workout.title : "",
      pro: workout && workout.Pro ? workout.Pro.id : "",
      skillLevel: workout ? workout.skillLevel : "",
      length: workout ? workout.length : 0,
      duration: workout ? workout.duration : "",
      description: workout ? workout.description : "",
      isActive: workout ? workout.isActive : false,
      imageUrl: workout ? workout.imageUrl : "",
      videoUrl: workout ? workout.videoUrl : "",
      exercises: [],
      exerciseCheck: exerciseCheck || false,
      equipments: {},
      categoryId: workout ? workout.categoryId : "",
    },

    onSubmit: async (values) => {
      const createList: any = [];
      const existingList: any = {};
      console.log("update exericses", values.exercises);

      for (let i = 0; i < values.exercises.length; i++) {
        const exercise: any = values.exercises[i];
        const { name, start, end, stationId } = exercise;
        if (exercise.type === "New") {
          createList.push({ name, start, end, stationId });
        } else {
          existingList[exercise.id] = { name, start, end, stationId };
        }
      }

      const equipments = Object.keys(values.equipments).filter(
        (el) => values.equipments[el] === true
      );
      if (workoutId) {
        await workoutMutation({
          variables: {
            data: {
              title: { set: values.title },
              skillLevel: { set: values.skillLevel },
              description: { set: values.description },
              imageUrl: { set: values.imageUrl },
              videoUrl: { set: values.videoUrl },
              length: { set: Math.round(values.length / 60) },
              duration: { set: values.duration },
              isActive: { set: values.isActive },
              Category: {
                connect: {
                  id: values.categoryId,
                },
              },
              equipments: { set: equipments },
              Exercises: {
                create: createList,
              },
              Pro: {
                connect: {
                  id: values.pro,
                },
              },
            },
            where: { id: workoutId },
          },
        });

        for (const key in existingList) {
          if (Object.prototype.hasOwnProperty.call(existingList, key)) {
            const element = existingList[key];
            await updateExercise({
              variables: {
                data: {
                  name: { set: element.name },
                  start: { set: element.start },
                  end: { set: element.end },
                  stationId: { set: element.stationId },
                },
                where: {
                  id: key,
                },
              },
            });
          }
        }
      } else {
        await workoutMutation({
          variables: {
            data: {
              title: values.title,
              skillLevel: values.skillLevel,
              description: values.description,
              imageUrl: values.imageUrl,
              length: Math.round(values.length / 60),
              duration: values.duration,
              videoUrl: values.videoUrl,
              isActive: values.isActive,
              Category: {
                connect: {
                  id: values.categoryId,
                },
              },
              Exercises: {
                create: createList,
              },
              equipments: { set: equipments },
              Pro: {
                connect: {
                  id: values.pro,
                },
              },
            },
          },
        });
      }
      history.push(`/workouts/${values.categoryId}`);
    },
  });

  // gql mutation

  const [workoutMutation, { loading }] = useMutation(props.workoutMutation, {
    onCompleted(data) {
      console.log("workout is loading ?");
      console.log(data);

      if (!data) {
        console.log("error ? data not received ?");
        return;
      }
    },
    onError({ message }) {
      return <div>{JSON.stringify(message)}</div>;
    },
  });

  /**
   * get equipment queries
   */
  const {
    loading: onboardingLoading,
    error,
    data: workoutQueryData,
  } = useQuery(GET_WORKOUT_FORM_DATA, {
    onCompleted({ onboardingInfos, pros, categories }) {
      const res = {};
      const data = onboardingInfos.forEach((el: any) => {
        res[el.label] = false;
      });

      workout &&
        workout.equipments.forEach((el) => {
          res[el] = true;
        });

      formik.setFieldValue("equipments", res);

      return;
    },
  });

  const [updateExercise, { loading: loadingExercise }] = useMutation(
    UPDATE_ONE_EXERCISE,
    {
      onCompleted(data) {
        console.log(data);
        console.log("hit here");

        return;
      },
      onError({ message }) {
        return <div>{JSON.stringify(message)}</div>;
      },
    }
  );

  // loading state
  if (loading) return <Loading />;

  if (onboardingLoading) return <Loading />;
  // render

  const { errors, touched } = formik;

  const handleRedirect = () => {
    history.push(`/workouts/${categoryId}`);
  };

  const handleEquipment = (e) => {
    const name = e.currentTarget.name;
    const equipments = formik.values.equipments;
    equipments[name] = !equipments[name];
    formik.setFieldValue("equipments", equipments);
  };

  const getExercises = (exercises) => {
    formik.setFieldValue("exercises", exercises);
  };

  return (
    <Container>
      <BACK_BUTTON to={`/workouts/${categoryId}`}>
        <FaChevronLeft fontSize={20} />
        <p>Back to Workout</p>
      </BACK_BUTTON>

      <MainForm onSubmit={formik.handleSubmit}>
        <FirstSection>
          <div>Add New Workout</div>
          <div className="flex-row">
            <div className="flex-col">
              {formik.values.title ? <LabelRow>title</LabelRow> : null}
              <TEXT_INPUT
                type="text"
                width={238}
                margin={[20, 0]}
                padding={[8, 0]}
                placeholder="Workout Title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
              />
              {errors.title && touched.title ? <Err>{errors.title}</Err> : null}
            </div>
            <div className="flex-row-switch">
              <p>Active </p>
              <Switch
                isOn={formik.values.isActive}
                onColor={theme.GREEN_COLOR}
                handleToggle={() =>
                  formik.setFieldValue("isActive", !formik.values.isActive)
                }
                className={"add-new"}
              />
            </div>
            <div className="flex-col">
              {formik.values.categoryId ? (
                <LabelRow>workout category</LabelRow>
              ) : null}

              <Selector
                fieldname="categoryId"
                field={workoutQueryData ? workoutQueryData.categories : []}
                formik={formik}
                id={formik.values.categoryId}
                manual={false}
                default={formik.values.categoryId}
                name="Workout Category"
              />
              {errors.categoryId && touched.categoryId ? (
                <SelctorError>{errors.categoryId}</SelctorError>
              ) : null}
            </div>
          </div>
        </FirstSection>

        <InputSection>
          <div className="flex-row">
            <div className="flex-col">
              {formik.values.pro ? <LabelRow>pro</LabelRow> : null}
              <Selector
                fieldname="pro"
                field={workoutQueryData ? workoutQueryData.pros : []}
                formik={formik}
                id={formik.values.pro}
                manual={false}
                default={formik.values.pro}
                name="Alter Pro"
              />
              {errors.pro && touched.pro ? (
                <SelctorError>{errors.pro}</SelctorError>
              ) : null}
            </div>

            <div className="flex-col">
              {formik.values.skillLevel ? (
                <LabelRow>skill level</LabelRow>
              ) : null}
              <Selector
                fieldname="skillLevel"
                field={null}
                formik={formik}
                manual={true}
                default={formik.values.skillLevel}
                name="Difficulty Level"
              />
              {errors.skillLevel && touched.skillLevel ? (
                <SelctorError>{errors.skillLevel}</SelctorError>
              ) : null}
            </div>
            <div className="flex-col">
              {formik.values.duration ? <LabelRow>duration</LabelRow> : null}

              <Selector
                fieldname="duration"
                field={null}
                formik={formik}
                default={formik.values.duration}
                manual={true}
                name="Workout Duration"
              />
              {errors.duration && touched.duration ? (
                <SelctorError>{errors.duration}</SelctorError>
              ) : null}
            </div>
            <TIME_INPUT
              type="text"
              placeholder="Workout Duration"
              name="length"
              value={formik.values.length || 0}
              onChange={formik.handleChange}
            />
          </div>
        </InputSection>

        <DescriptionSection>
          <div className="flex-col">
            {formik.values.description ? (
              <LabelRow>description</LabelRow>
            ) : null}

            <TEXTAREA
              width={600}
              margin={[20, 0]}
              padding={[8, 0]}
              placeholder="Workout Description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
            {errors.description && touched.description ? (
              <Err>{errors.description}</Err>
            ) : null}
          </div>
        </DescriptionSection>

        <UploadSection>
          <div className="flex-row">
            <div>
              <VideoUpload formik={formik} />
              {errors.videoUrl && touched.videoUrl ? (
                <Err>{errors.videoUrl}</Err>
              ) : null}
            </div>
            <div>
              <ImageUpload formik={formik} />
              {errors.imageUrl && touched.imageUrl ? (
                <Err>{errors.imageUrl}</Err>
              ) : null}
            </div>
          </div>
        </UploadSection>

        <ExerciseSection>
          {errors.exerciseCheck && touched.exerciseCheck ? (
            <Err>{errors.exerciseCheck}</Err>
          ) : null}
          <Exercises
            workoutId={workoutId}
            videoLength={formik.values.length}
            getExercises={getExercises}
            formik={formik}
          />
        </ExerciseSection>

        <EquipmentSection>
          <h3>Equipment Needed</h3>
          <div className="flex-row">
            <div className="flex-col">
              <Equipment
                name="Foam Rollers"
                onClick={handleEquipment}
                className={
                  formik.values.equipments["Foam Rollers"]
                    ? "selected"
                    : "not-selected"
                }
              >
                <Roller name="Foam Rollers" />
              </Equipment>
              <p>Form Roller</p>
            </div>
            <div className="flex-col">
              <Equipment
                name="Medicine Ball"
                onClick={handleEquipment}
                className={
                  formik.values.equipments["Medicine Ball"]
                    ? "selected"
                    : "not-selected"
                }
              >
                <Ball />
              </Equipment>
              <p>Medicine Ball</p>
            </div>
            <div className="flex-col">
              <Equipment
                name="Jump Rope"
                onClick={handleEquipment}
                className={
                  formik.values.equipments["Jump Rope"]
                    ? "selected"
                    : "not-selected"
                }
              >
                <Rope />
              </Equipment>
              <p>Jump Rope</p>
            </div>
            <div className="flex-col">
              <Equipment
                name="Kettlebells"
                onClick={handleEquipment}
                className={
                  formik.values.equipments["Kettlebells"]
                    ? "selected"
                    : "not-selected"
                }
              >
                <Kettlebells />
              </Equipment>
              <p>Kettlebells</p>
            </div>
            <div className="flex-col">
              <Equipment
                name="Dumbells"
                onClick={handleEquipment}
                className={
                  formik.values.equipments["Dumbells"]
                    ? "selected"
                    : "not-selected"
                }
              >
                <Dumbell />
              </Equipment>
              <p>Dumbells</p>
            </div>
          </div>
        </EquipmentSection>
        <SubmitSection>
          <div className="flex-col">
            <FlexRow>
              <SAVE_BUTTON type="submit">Save</SAVE_BUTTON>
              <SAVE_BUTTON type="button" background={theme.CANCEL_COLOR}>
                Cancel
              </SAVE_BUTTON>
            </FlexRow>
            {workoutId ? (
              <DeleteWorkout
                categoryId={categoryId}
                workoutId={workoutId}
                text="Delete Workout"
              />
            ) : null}
          </div>
        </SubmitSection>
      </MainForm>
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: 1px 121px 1px 119px;
`;

const MainForm = styled.form`
  border-radius: 4px;
  background-color: #f6f6f6;
  display: grid;
  grid-gap: 10px;
  & > * {
    margin: 36px 51px;
  }
  section {
    grid-column: 1 / span 6;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    & > * {
      margin-right: 20px;
    }
  }
  .flex-col {
    display: flex;
    flex-direction: column;
  }
  .flex-row-switch {
    display: flex;
    align-self: center;
    & > * {
      padding: 5px;
    }
  }
`;

const FirstSection = styled.section`
  grid-row: 1;
`;
const InputSection = styled.section`
  margin-top: 57px;
  grid-row: 2;
`;

const DescriptionSection = styled.section`
  grid-row: 3;
`;

const UploadSection = styled.section`
  grid-row: 4;
`;

const ExerciseSection = styled.section`
  display: flex;
  flex-direction: column;
  font-size: 12px;


  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > * {
      margin-right: 20px;
    }
`;

export interface EquipmentProps {
  color?: string;
  fill?: string;
}
const EquipmentSection = styled.label<EquipmentProps>`
  display: flex;
  flex-direction: column;

  font-size: 9px;
  .flex-col {
    align-items: center;
  }
  h3 {
    font-size: 14px;
    fill: ${(props) => props.color || theme.BLACK_COLOR};
  }
  .selected {
    background:#eb506e;
    svg g  {
    fill-opacity:1;
    fill: white;
  }
  .not-selected {
    
    svg g  {
    fill: none;
  }
`;

const Equipment = styled.object`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background: white;
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;
const SubmitSection = styled.section`
  margin: auto;
  margin-top: 157px;
  margin-bottom: 157px;
  text-align: center;
`;

const TIME_INPUT = styled.input`
  background: none;
  border: none;
  display: none;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    margin: 26px;
  }
`;

const SelctorError = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: -0.05px;
  color: ${theme.INPUT_BORDER_COLOR};
  margin-top: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  padding-botom: 10px;
`;

const LabelRow = styled.label`
  display: inline;
  position: absolute;
  margin-top: 10px;
  background: none;
  border: none;
  font-family: DMSans;
  height: 10px;
  opacity: 0.4;
  font-size: 10px;
  font-weight: 500;
`;
