import { gql } from "@apollo/client";
import {
  ProDetails,
  ProMin,
  WorkoutDashboard,
  WorkoutDetail,
  Promotion,
  CategoryFragment,
  Firmware,
} from "../fragments";

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const AdminFragment = gql`
  fragment Admin on User {
    id
    email
    sessionToken
    name
  }
`;

export const GET_CATEGORIES = gql`
  query categories {
    categories(orderBy: { sortOrder: asc }) {
      ...Category
    }
  }
  ${CategoryFragment}
`;

export const GET_CATEGORY = gql`
  query category($data: CategoryWhereUniqueInput!) {
    category(where: $data) {
      id
      title
      isActive
      imageUrl
    }
  }
`;

export const GET_WORKOUTS = gql`
  query workouts($data: WorkoutWhereInput) {
    workouts(where: $data, orderBy: { sortOrder: asc }) {
      ...Workout
    }
    categories {
      id
      title
    }
  }
  ${WorkoutDashboard}
`;

export const GET_WORKOUT = gql`
  query workouts($data: WorkoutWhereUniqueInput!) {
    workout(where: $data) {
      ...Workout
    }
  }
  ${WorkoutDetail}
`;

export const GET_PROS = gql`
  query pros($data: ProWhereInput) {
    pros(where: $data) {
      id
      name
    }
  }
`;

export const GET_EXERCISES = gql`
  query exercises(
    $where: ExerciseWhereInput
    $orderBy: [ExerciseOrderByInput!]
  ) {
    exercises(where: $where, orderBy: $orderBy) {
      id
      name
      start
      end
      stationId
    }
  }
`;

export const GET_ALL_PROS = gql`
  query pros {
    pros {
      ...Pro
    }
  }
  ${ProMin}
`;

export const GET_ONE_PRO = gql`
  query pro($data: ProWhereUniqueInput!) {
    pro(where: $data) {
      ...Pro
    }
  }
  ${ProDetails}
`;

export const GET_PRO_MUSIC = gql`
  query proMusic($data: proMusicWhereUniqueInput!) {
    proMusic(where: $data) {
      id
      photo
      musicUrl
      proId
    }
  }
`;

export const GET_ALL_PROMOTIONS = gql`
  query promotionalItems {
    promotionalItems(orderBy: { sortOrder: asc }) {
      ...Promotion
    }
  }
  ${Promotion}
`;

export const GET_ONE_PROMOTION = gql`
  query PromotionalItem($data: PromotionalItemWhereUniqueInput!) {
    promotionalItem(where: $data) {
      ...Promotion
    }
  }
  ${Promotion}
`;

export const FFTCH_EQUIPMENT = gql`
  query onboardingInfos {
    onboardingInfos(where: { type: Equipment }) {
      label
      value
    }
  }
`;

export const GET_ALL_FIRMWARE = gql`
  query firmwareContents {
    firmwareContents(orderBy: { version: desc }) {
      ...FirmwareContent
    }
  }
  ${Firmware}
`;

export const GET_ONE_FIRMWARE = gql`
  query firmwareContent($data: FirmwareContentWhereUniqueInput!) {
    firmwareContent(where: $data) {
      ...FirmwareContent
    }
  }
  ${Firmware}
`;

export const GET_WORKOUT_FORM_DATA = gql`
  query pros($data: ProWhereInput) {
    pros(where: $data) {
      id
      name
    }
    categories {
      id
      title
    }
    onboardingInfos(where: { type: Equipment }) {
      label
      value
    }
  }
`;
