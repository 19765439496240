import React, { useEffect, useLayoutEffect, useState } from "react";
var Feed = require("feed-media-audio-player");

var player = new Feed.Player(
  "e9b24174076e2db892f253f48e3b735c6a159717",
  "b4dd3d5082bde3b81b8c2c041036774aaaecfaa6"
);

player.tune();

export const MusicPlayer = (props) => {
  const [station, changeStationName] = useState("");
  let stationList;
  player.on("all", function (event, data) {
    console.log("music player state ", player.state);
    console.log(station);

    console.log("player triggered event '" + event);
    console.log(data);
  });
  let playerView;
  useLayoutEffect(() => {
    playerView = new Feed.PlayerView("player-view-div", player);
    console.log(playerView);

    console.log(Feed);
  });

  useEffect(() => {
    player.getStations().then((list) => {
      console.log("station list", list);
      stationList = list;
    });
  });

  console.log(stationList);

  const changeStation = () => {
    const stationIds = stationList.map((el) => el.id);
    let random = Math.floor(Math.random() * stationIds.length);
    changeStationName(stationList[random].name);
    player.setStationId(stationIds[random]);
  };

  return (
    <div>
      <div id="player-view-div" style={{ color: "white" }}>
        <div>{station}</div>

        <div className="status"></div>
        <button className="play-button">play</button>
        <button className="pause-button">pause</button>
        <button className="skip-button">skip</button>
        <button className="random" onClick={() => changeStation()}>
          change Station
        </button>
      </div>
    </div>
  );
};
