import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../../../store";
import { theme } from "../../../utils/theme";
import { Selector, TEXT_INPUT } from "../../UI";

export const Exercise = (props) => {
  const { dispatch, state } = useContext(AppContext);
  const { formik, stationList } = props;
  const [exercise, setExercise] = useState({
    name: props.name,
    start: props.start,
    end: props.end,
    exerciseId: props.exerciseId,
    stationId: props.stationId,
    first: false,
    startMin: Math.floor(props.start / 60),
    startSec: Number(props.start % 60),
    videoDuration: 0,
    type: props.type,
  });

  const updateNumberToTime = (num: any) => {
    let sec_num: any = parseInt(num);
    let minutes: any = Math.floor(sec_num / 60);
    let seconds: any = sec_num - minutes * 60;

    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
  };

  useEffect(() => {
    setExercise({
      ...exercise,
      end: props.end,
      videoDuration: props.videoDuration,
    });
  }, [props]);

  const handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    let value = target.value;
    const { exerciseId } = exercise;
    let {
      name,
      start,
      end,
      startMin,
      startSec,
      videoDuration,
      stationId,
      type,
    } = exercise;
    start = startMin * 60 + startSec * 1;
    if (target.name === "startMin" || target.name === "startSec") {
      if (value < 0) {
        value = 0;
      } else if (
        target.name === "startMin" &&
        value > Math.floor(videoDuration / 60)
      ) {
        value = Math.floor(videoDuration / 60);
      } else if (target.name === "startSec") {
        if (startMin >= Math.floor(videoDuration / 60)) {
          let maxCap = videoDuration % 60;
          if (value >= maxCap) {
            value = maxCap;
          }
        } else {
          if (value >= 60) {
            value = 59;
          }
        }
      }
    }
    setExercise({ ...exercise, [target.name]: value, start });
    props.updateParent({
      [exerciseId]: {
        name,
        start,
        end,
        type,
        stationId,
        id: exerciseId,
      },
    });
  };

  const enterHandler = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();

      // const target = e.target;
      // let value = exercise.duration;
      // setExercise({ ...exercise, end: value + exercise.start });
      // const { exerciseId } = exercise;
      // let { name, start, end, startMin, startSec } = exercise;
      // start = startMin * 60 + startSec;
      // end = parseInt(end);
    }
  };
  const exetciseLabel = props.index === 0 ? "Exercises" : "";
  const timeStampLabel = props.index === 0 ? "Timestamp" : "";
  const startTime = props.index === 0 ? "Start Time" : "";
  const setStationId = (value) => {
    setExercise({ ...exercise, stationId: value });
    const { exerciseId } = exercise;
    let { name, start, end, type } = exercise;

    props.updateParent({
      [exerciseId]: {
        name,
        start,
        end,
        type,
        stationId: value,
        id: exerciseId,
      },
    });
  };

  return (
    <div>
      <ExerciseRow>
        <div>
          <p>{exetciseLabel}</p>
          <TEXT_INPUT
            type="text"
            width={238}
            margin={[0, 0]}
            padding={[8, 0]}
            placeholder="Exercise Name"
            name="name"
            value={exercise.name}
            onChange={handleChange}
            onKeyUp={handleChange}
            onKeyDown={enterHandler}
          />
        </div>

        <div>
          <p>{startTime}</p>
          <START_TIME_DIV>
            <START_INPUT
              type="number"
              name="startMin"
              min={0}
              value={Number(exercise.startMin).toString()}
              onChange={handleChange}
              onKeyUp={handleChange}
              onKeyDown={enterHandler}
            />
            <p> : </p>
            <START_INPUT
              type="number"
              name="startSec"
              min={0}
              value={Number(exercise.startSec).toString()}
              onChange={handleChange}
              onKeyUp={handleChange}
              onKeyDown={enterHandler}
            />
          </START_TIME_DIV>
        </div>
        <div>
          <p>{timeStampLabel}</p>
          <TIME_DIV>
            {updateNumberToTime(exercise.start)} -{" "}
            {updateNumberToTime(exercise.end)}
          </TIME_DIV>
        </div>

        <TIME_INPUT
          type="number"
          placeholder="End Time"
          name="end"
          min={0}
          required
          max={props.max}
          value={Math.round(exercise.end)}
          onChange={handleChange}
        />
        <Selector
          fieldname="Station List"
          field={stationList}
          formik={formik}
          manual={false}
          id={exercise.stationId}
          onUpdate={setStationId}
          onKeyUp={setStationId}
          name="Station List"
        />
      </ExerciseRow>
    </div>
  );
};

const TIME_INPUT = styled.input`
  background: none;
  border: none;
  display: none;
`;

const ExerciseRow = styled.div`
  align-items: flex-end;
  display: flex;
  & > * {
    margin-right: 20px;
  }
`;

const START_INPUT = styled.input`
  background: none;
  border: none;
  &::-webkit-autofill,
  &::-webkit-autofill:hover,
  &::-webkit-autofill:focus,
  &::-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
  padding: 8px 0;
  max-width: 30px;
  border-bottom: 1px solid ${theme.INPUT_BORDER_COLOR};
`;

const TIME_DIV = styled.div`
  background: none;
  border: none;
  border-bottom: 1px solid ${theme.INPUT_BORDER_COLOR};
  padding: 9px 0;
`;

export interface LabelProps {
  width?: number;
  fontSize?: number;
  color?: string;
  margin?: Array<number>;
  padding?: Array<number>;
}

export const Label = styled.p<LabelProps>`
  background: none;
  border: none;
  margin: ${(props) =>
    props.margin ? `${props.margin[0]}px ${props.margin[1]}px` : `38px 10px`};
  padding: ${(props) =>
    props.padding ? `${props.padding[0]}px ${props.padding[1]}px` : `10px 5px`};
  width: ${(props) => props.width || 345}px;
  font-family: ${theme.PRIMARY_FONT};
  height: 10px;
  opacity: 0.4;
  font-size: 8px;
  font-weight: 500;
  letter-spacing: -0.06px;
  color: #212121;
`;

const START_TIME_DIV = styled.div`
  display: flex;
  flex-direction: row;
  align-text: center;
  height: 34px;
  & * > {
    border: none;
  }
`;
