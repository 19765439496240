import { useQuery } from "@apollo/client";
import * as React from "react";
import { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import makeAnimated from "react-select/animated";
import styled from "styled-components";
import { GET_CATEGORIES } from "../../apollo/queries";
import { theme } from "../../utils/theme";
import { Loading } from "./Loading";

const LevelOption: any = [
  { value: "Beginner", label: "Beginner" },
  { value: "Intermidiate", label: "Intermidiate" },
  { value: "Advanced", label: "Advanced" },
];

const DurationOption: any = [
  { value: "5 Minutes", label: "5 Minutes" },
  { value: "15 Minutes", label: "15 Minutes" },
  { value: "30 Minutes", label: "30 Minutes" },
  { value: "45 Minutes", label: "45 Minutes" },
  { value: "60 Minutes", label: "60 Minutes" },
];

export const Selector = (props) => {
  let {
    field,
    fieldname,
    formik,
    id,
    manual,
    name,
    onUpdate,
    color,
    marginTop,
  } = props;

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(props.default);

  const handleTypeSelect = (e) => {
    if (!onUpdate) {
      formik.setFieldValue(fieldname, e.value);
    } else {
      onUpdate(e.value);
    }
    setSelectedOption(e.value);
  };

  // const setDefaultOption = ()
  useEffect(() => {
    if (fieldname === "skillLevel") {
      setOptions(LevelOption);
    }

    if (fieldname === "duration") {
      setOptions(DurationOption);
    }

    if (field && !manual) {
      let data;

      if (name === "Workout Category") {
        data = field.map((el) => {
          return { value: el.id, label: el.title };
        });
        if (onUpdate) {
          data.unshift({
            value: "All Workouts",
            label: "All Workouts",
          });
        }
      } else {
        data = field.map((el) => {
          return { value: el.id, label: el.name };
        });
      }

      setOptions(data);
      // console.log(id);

      setSelectedOption(id);
    }
  }, [props.field]);

  if (!options) {
    return <Loading />;
  }

  const defaultOption = () => {
    const data = options.filter((option: any) => {
      return option.value === selectedOption;
    });

    return data;
  };

  const customStyles: StylesConfig = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      fontSize: `12px`,
      fontWeight: `normal`,
      letterSpacing: ` -0.09px`,
      color: isSelected ? `#eb506e` : "black",
      border: "none",
      fontFamily: theme.PRIMARY_FONT,
      backgroundColor: isFocused ? theme.LIGHT_GREY_COLOR : "none",
    }),
    dropdownIndicator: (provided, state) => ({
      color: "red",
      direction: "revert",
      transform: state.selectProps.menuIsOpen && "rotate(180deg)",
    }),
    container: (provided, state) => ({
      ...provided,
      color: "red",
      display: "inherit",
      position: "relative",
    }),
    menu: (provided, state) => ({
      ...provided,
    }),
    input: (styles) => ({
      color: "black",
      display: "inherit",
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      borderBottom: `1px solid ${theme.INPUT_BORDER_COLOR}`,
      display: "flex",
      width: 300,
      margin: "auto",
      marginTop: marginTop ? marginTop : 31,
    }),
    indicatorSeparator: (styles) => ({
      border: "none",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return {
        ...provided,
        opacity,
        transition,
        paddingBottom: "10px",
        color: props.color ? props.color : theme.BLACK_COLOR,
      };
    },
  };

  return (
    <Select
      value={defaultOption()}
      placeholder={name}
      onChange={handleTypeSelect}
      options={options}
      type="text"
      styles={customStyles}
    />
  );
};

const animatedComponents = makeAnimated();

export const AnimatedMulti = (props) => {
  let { field, fieldname, formik, id, defaultField } = props;

  const { data: catData, loading: catLoading } = useQuery(GET_CATEGORIES);

  if (catLoading) {
    return <Loading />;
  }

  const handleChange = (data) => {
    const res = data ? data.map((el) => el.value) : [];
    formik.setFieldValue("specialties", res);
  };

  const defaultData = defaultField.map((el) => {
    return { value: el, label: el };
  });
  const optionData = catData.categories.map((el) => {
    return { value: el.title, label: el.title };
  });

  return (
    <div>
      <MULTI_SELECT
        closeMenuOnSelect={false}
        components={animatedComponents}
        defaultValue={defaultData}
        isMulti
        options={optionData}
        placeholder="Specialty"
        padding={[0, 0]}
        onChange={handleChange}
      />
    </div>
  );
};

export interface SelectorProps {
  wtitleth?: number;
  fontSize?: number;
  color?: string;
  margin?: Array<number>;
  padding?: Array<number>;
}

export const MULTI_SELECT = styled(Select)<SelectorProps>`
  background: none;
  border: none;
  font-family: ${theme.PRIMARY_FONT};
  margin: ${(props) =>
    props.margin ? `${props.margin[0]}px ${props.margin[1]}px` : `38px 0px`};
  padding: ${(props) =>
    props.padding ? `${props.padding[0]}px ${props.padding[1]}px` : `10px 5px`};
  width: ${(props) => props.width || 345}px;
  font-family: ${theme.PRIMARY_FONT};
  border-bottom: 1px solid ${theme.INPUT_BORDER_COLOR};
  color: ${(props) => props.color || theme.BLACK_COLOR};
  borderbottom: 1px solid ${theme.INPUT_BORDER_COLOR};
  boxshadow: none;
  & > div,
  span {
    boxshadow: none;
    background: none;
    border: none;
    font-size: smaller;
  }
  &:focus {
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  .css-26l3qy-menu {
    background: white;
  }
  &:focus {
    outline: none;
  }
  .css-g1d714-ValueContainer {
    padding: 0;
  }
`;
